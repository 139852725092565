import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import type { PayloadAction, ThunkAction, Action } from '@reduxjs/toolkit'
import type { RootState } from '..'
import type { Course, Lesson } from '../../interfaces/mongoose.gen'
import { updateLesson as updateLessonAction } from './courses'

interface DeleteLessonPayload {
  _id: string
}

interface UploadFilePayload {
  course: string
  week: string
  lesson: string
  lang: string
  filename: string
  path: string
}

interface User {
  fullName: string
  sid: string
}

interface AdminCoursesState {
  token: string | null
  isInitialRequestDone: boolean
  isRequesting: boolean
  user: User
}

const initialState: AdminCoursesState = {
  token: null,
  isInitialRequestDone: false,
  isRequesting: false,
  user: {
    fullName: '',
    sid: ''
  }
}

const adminCoursesSlice = createSlice({
  name: 'adminCourses',
  initialState,
  reducers: {
    setSid: (state, action: PayloadAction<string>) => {
      state.user.sid = encodeURIComponent(action.payload)
    },
    userLogout: (state) => {
      state.user = initialState.user
      state.token = null
    },
    initialRequestDone: (state) => {
      state.isInitialRequestDone = true
    },
    deleteLessonRequested: () => {},
    deleteLessonFailed: (state, action: PayloadAction<Error>) => {},
    editLessonRequested: () => {},
    editLessonFailed: (state, action: PayloadAction<Error>) => {},
    uploadFileRequested: () => {},
    uploadFileFailed: (state, action: PayloadAction<Error>) => {},
  }
})

export const {
  setSid,
  userLogout,
  initialRequestDone,
  deleteLessonRequested,
  deleteLessonFailed,
  editLessonRequested,
  editLessonFailed,
  uploadFileRequested,
  uploadFileFailed,
} = adminCoursesSlice.actions

export const deleteLesson = (
  payload: DeleteLessonPayload
): ThunkAction<Promise<unknown>, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch(deleteLessonRequested())
  try {
    const res = await fetch(`/api/v1/courses/lesson/${payload._id}`, {
      method: 'DELETE'
    })

    const json = await res.json()
    return json
  } catch (error) {
    if (error instanceof Error) {
      dispatch(deleteLessonFailed(error))
    }
    throw error
  }
}

export const updateLesson = (
  payload: Lesson
): ThunkAction<Promise<unknown>, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    const res = await fetch(`/api/v1/courses/lesson/${payload._id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
    const json = await res.json()
    
    // Update the lesson in the Redux store
    const state = getState();
    const courses = state.courses.list;
    
    if (courses && courses.length > 0) {
      // Find the course and week that contain this lesson
      for (const course of courses) {
        if (course.weeks) {
          for (const week of course.weeks) {
            if (week.lessons) {
              const lessonIndex = week.lessons.findIndex(l => l._id === payload._id);
              if (lessonIndex !== -1 && course.courseId && week._id) {
                // Update the lesson in the courses reducer
                dispatch(updateLessonAction({
                  courseId: course.courseId,
                  weekId: week._id,
                  lesson: payload
                }));
              }
            }
          }
        }
      }
    }
    
    // Invalidate Redis cache after successful update
    if (json.status === 'ok') {
      try {
        await fetch('/api/v1/courses/invalidate-cache', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        })
      } catch (cacheError) {
        console.error('Failed to invalidate cache:', cacheError)
        // Don't throw error here, as the lesson was updated successfully
      }
    }
    
    return json
}

export const createLesson = (
  payload: Lesson
): ThunkAction<Promise<unknown>, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch(editLessonRequested())
  try {
    const res = await fetch('/api/v1/courses/lesson', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })

    const json = await res.json()
    
    // Invalidate Redis cache after successful creation
    if (json.status === 'ok') {
      try {
        await fetch('/api/v1/courses/invalidate-cache', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        })
      } catch (cacheError) {
        console.error('Failed to invalidate cache:', cacheError)
        // Don't throw error here, as the lesson was created successfully
      }
    }
    
    return json
  } catch (error) {
    if (error instanceof Error) {
      dispatch(editLessonFailed(error))
    }
    throw error
  }
}

export const create = createAsyncThunk<Course, Course | null, { state: RootState }>(
  'adminCourses/create',
  async (payload: Course | null, { getState }) => {
    const { token } = getState().authentication;

    try {
      const res = await fetch('/api/v1/admin/courses/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload),
      });

      const json = await res.json()
      return json
    } catch (error) {
      console.log(error);
      throw error
    }
  }
);

export const editCourse = (
  payload: Course
): ThunkAction<Promise<unknown>, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    const res = await fetch('/api/v1/admin/courses/edit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })

    if (res.status === 404) {
      throw Error('server side is down')
    }

    const json = await res.json()
    
    // Invalidate Redis cache after successful save
    if (json.status === 'ok') {
      try {
        await fetch('/api/v1/courses/invalidate-cache', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        })
      } catch (cacheError) {
        console.error('Failed to invalidate cache:', cacheError)
        // Don't throw error here, as the course was saved successfully
      }
    }
    
    return json
  } catch (error) {
    if (error instanceof Error) {
      dispatch(editLessonFailed(error))
    }
    throw error
  }
}

export const uploadFile = (
  payload: UploadFilePayload,
  file: File
): ThunkAction<Promise<unknown>, RootState, unknown, Action<string>> => async (dispatch) => {
  const data = new FormData()
  data.append('video', file)

  dispatch(uploadFileRequested())
  try {
    const res = await fetch('/api/v1/admin/courses/upload', {
      method: 'POST',
      body: data,
      headers: {
        key: payload.path,
        course: payload.course,
        week: payload.week,
        lesson: payload.lesson,
        lang: payload.lang,
        filename: payload.filename
      }
    })

    const json = await res.json()
    return json
  } catch (error) {
    if (error instanceof Error) {
      dispatch(uploadFileFailed(error))
    }
    throw error
  }
}

export default adminCoursesSlice.reducer
