import React from 'react'
import ReactMarkdown from 'react-markdown'
import type { ReactElement } from 'react'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import type { Components } from 'react-markdown'
import { PlaygroundWrapper } from './PlaygroundWrapper'
import { Code, Pre } from './CodeHighlighting'
import { Table, TableHead, TableBody, TableRow, TableCell, TableHeader } from './TableComponents'

interface MarkdownProcessorProps {
  markdown: string;
  onGenerateContent?: (content: string) => void;
}

// Define the components for ReactMarkdown
const components: Partial<Components> = {
  code: Code as Components['code'],
  pre: Pre as Components['pre'],
  table: Table as Components['table'],
  thead: TableHead as Components['thead'],
  tbody: TableBody as Components['tbody'],
  tr: TableRow as Components['tr'],
  td: TableCell as Components['td'],
  th: TableHeader as Components['th']
}

export const processMarkdown = (markdown: string): ReactElement[] => {
  const parts = markdown.split(/(<playground[^>]*>[\s\S]*?<\/playground>)/)
  let nonPlaygroundContent = ''
  const elements: ReactElement[] = []

  parts.forEach((part, index) => {
    const match = part.match(/<playground type="([^"]+)">([\s\S]*?)<\/playground>/)
    if (match) {
      if (nonPlaygroundContent) {
        elements.push(
          <ReactMarkdown 
            key={`md-${elements.length}`} 
            components={components}
            remarkPlugins={[remarkMath, remarkGfm]}
            rehypePlugins={[rehypeKatex, rehypeRaw]}
          >
            {nonPlaygroundContent}
          </ReactMarkdown>
        )
        nonPlaygroundContent = ''
      }

      const [, type, code] = match
      elements.push(
        <PlaygroundWrapper
          key={`pg-${elements.length}`}
          code={code}
          type={type}
        />
      )
    } else {
      nonPlaygroundContent += part
    }
  })

  if (nonPlaygroundContent) {
    elements.push(
      <ReactMarkdown 
        key={`md-${elements.length}`} 
        components={components}
        remarkPlugins={[remarkMath, remarkGfm]}
        rehypePlugins={[rehypeKatex, rehypeRaw]}
      >
        {nonPlaygroundContent}
      </ReactMarkdown>
    )
  }

  return elements
}

export const MarkdownProcessor: React.FC<MarkdownProcessorProps> = ({ markdown }) => {
  return (
    <div className="prose prose-lg max-w-none dark:prose-invert prose-headings:font-medium prose-a:text-blue-600 dark:prose-a:text-blue-400 prose-pre:p-0 prose-pre:m-0 prose-img:rounded-lg">
      {processMarkdown(markdown)}
    </div>
  );
};
