import { useState, useEffect } from "react";
import type { FC } from "react";
import { SparklesIcon } from "@heroicons/react/24/outline";

interface DeepSeekGeneratorProps {
	onGenerateContent: (content: string) => void;
	topic?: string;
}

const DeepSeekGenerator: FC<DeepSeekGeneratorProps> = ({
	onGenerateContent,
	topic,
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [prompt, setPrompt] = useState(`Составь **подробный, современный и практически полезный урок** по теме "${topic}" для начинающих, слабо знакомых с этой областью.

Цель урока — не просто объяснить тему, а **подготовить ученика к стажировке или трудоустройству**, снабдив его актуальными знаниями и реальными практиками.

**Важно:** урок должен быть **глубоким, технически точным, не поверхностным**, и полезным в реальной работе. Не бойся использовать термины и продвинутые идеи, **если ты их объясняешь простым языком**.

### Структура и требования к уроку:

1. **Объяснение ключевых понятий** — на простом, дружелюбном языке, будто объясняешь другу без бэкграунда.  
2. **Современные практики и инструменты**, актуальные на 2024–2025 гг. Упоминай, какие устарели и какие используются в индустрии.
3. **Пошаговое обучение через код** — показывай каждое действие с пояснением, что и почему делается.
4. **Реальные примеры** с работающим, хорошо прокомментированным кодом.
5. **Мини-проект**, имитирующий реальную рабочую задачу, с постановкой, реализацией и выводами.
6. **Разбор вакансий или интервью** — как тема используется в реальных задачах, типичные вопросы, советы по подготовке к интервью.
7. **Советы по углублению знаний** — что почитать, какие pet-проекты сделать, где практиковаться.
8. Весь урок должен быть **в формате Markdown**, с хорошо структурированными заголовками, подзаголовками, блоками кода и списками.

### Формат:
- Стиль — дружелюбный, мотивирующий, но профессиональный (как у лучших преподавателей на Udemy, Scrimba или FrontendMasters).
- Подход — как у менторов, которые действительно хотят помочь ученику пройти собес и устроиться на работу.

**Примерные инструменты, которые можешь включить, если уместно:** TypeScript, React, Tailwind, Docker, Git, GitHub Actions, Postgres, Prisma, REST/GraphQL, Jest, Playwright, Zod, Vite, ESLint, Prettier, и т.д.

⚠️ Не упрощай слишком сильно — цель не просто объяснить "что это", а **дать ученику реальную рабочую пользу** и **подготовить к реальной разработке**.
`);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [hasDeepSeekKey, setHasDeepSeekKey] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);

	useEffect(() => {
		// Check if the user is an admin and has a DeepSeek API key
		const checkUserAndKey = async () => {
			try {
				const response = await fetch("/api/v1/admin/user/current");
				const data = await response.json();

				if (data.status === "ok" && data.data) {
					// Check if user is admin
					setIsAdmin(data.data.role === "admin");
					
					// Check if user has DeepSeek API key
					const apiKeys = data.data.apiKeys || [];
					const hasKey = apiKeys.some(
						(key: { service: string }) => key.service === "deepseek",
					);
					setHasDeepSeekKey(hasKey);
				}
			} catch (error) {
				console.error("Failed to check user status and DeepSeek API key:", error);
				setIsAdmin(false);
				setHasDeepSeekKey(false);
			}
		};

		checkUserAndKey();
	}, []);

	const handleGenerate = async () => {
		if (!prompt.trim()) return;

		setIsLoading(true);
		setError(null);

		try {
			const response = await fetch("/api/v1/ai/deepseek/generate", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ prompt }),
			});

			if (!response.ok) {
				const errorData = await response.json();
				throw new Error(errorData.message || "Failed to generate content");
			}

			const data = await response.json();

			if (data.status === "ok" && data.content) {
				onGenerateContent(data.content);
				setIsModalOpen(false);
				setPrompt("");
			} else {
				throw new Error("Invalid response from server");
			}
		} catch (error) {
			console.error("Failed to generate content:", error);
			setError(
				error instanceof Error ? error.message : "An unknown error occurred",
			);
		} finally {
			setIsLoading(false);
		}
	};

	// Only show the button if the user is an admin and has a DeepSeek API key
	if (!isAdmin || !hasDeepSeekKey) return null

	return (
		<>
			<button
				type="button"
				onClick={() => setIsModalOpen(true)}
				className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
				title="Generate content with DeepSeek AI"
			>
				<SparklesIcon className="h-4 w-4 mr-1" aria-hidden="true" />
				Generate with AI
			</button>

			{isModalOpen && (
				<div className="fixed inset-0 z-50 overflow-y-auto">
					<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
						<div
							className="fixed inset-0 transition-opacity"
							aria-hidden="true"
						>
							<div className="absolute inset-0 bg-gray-500 opacity-75" />
						</div>

						<span
							className="hidden sm:inline-block sm:align-middle sm:h-screen"
							aria-hidden="true"
						/>

						<div className="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
							<div>
								<div className="mt-3 text-center sm:mt-5">
									<h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
										Generate Content with DeepSeek AI
                    {topic}
									</h3>
									<div className="mt-2">
										<p className="text-sm text-gray-500 dark:text-gray-400">
											Describe what you want to generate and DeepSeek AI will
											create content for your lesson.
										</p>
									</div>
								</div>
							</div>

							<div className="mt-5">
								<textarea
									rows={4}
									className="shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 dark:border-gray-700 rounded-md dark:bg-gray-700 dark:text-white"
		
									value={prompt}
									onChange={(e) => setPrompt(e.target.value)}
									disabled={isLoading}
								/>
							</div>

							{error && (
								<div className="mt-3">
									<p className="text-sm text-red-600 dark:text-red-400">
										{error}
									</p>
								</div>
							)}

							<div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
								<button
									type="button"
									className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm disabled:bg-gray-400 disabled:cursor-not-allowed"
									onClick={handleGenerate}
									disabled={isLoading || !prompt.trim()}
								>
									{isLoading ? "Generating..." : "Generate"}
								</button>
								<button
									type="button"
									className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-700 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
									onClick={() => setIsModalOpen(false)}
									disabled={isLoading}
								>
									Cancel
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default DeepSeekGenerator;
