import type { ReactNode } from 'react'
import { Suspense, useEffect, lazy } from 'react'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import { Routes, Route, BrowserRouter, useNavigate, useLocation, useParams, Outlet } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import store, { useAppSelector } from '@data'

import NotFound from '../components/landings/404'
import Login from '../components/login'
import ForgotPassword from '../components/forgot-password'
import ErrorPage from '../components/landings/error-page'
import CookieConsent from '../components/common/CookieConsent'

import LandingDefault from '../components/landings/default'
import LandingDefaultCourses from '../components/landings/courses'
import LandingDefaultCourseEnroll from '../components/landings/enroll'
import LandingDefaultCourseDetails from '../components/landings/course-details'
import LandingDefaultFaq from '../components/landings/faq'
import LandingDefaultTermsAndConditions from '../components/landings/terms-and-conditions'
import LandingDefaultDisclaimers from '../components/landings/disclaimers'
import LandingDefaultPrivacyPolicy from '../components/landings/privacy-policy'
import Playground from '../components/playground'
import Startup from './startup'
import queryString from '../helpers/query-string'
import i18n from './i18n'
import Loader from '../components/common/loader'
import CourseList from '../components/courses/list'
import Profile from '../components/user/profile'
import ProfileJobSearch from '../components/user/job-search'
import ProfileSocial from '../components/user/social-profile'
import Billing from '../components/user/billing'
import Security from '../components/user/security'
import ApiKeys from '../components/user/api-keys'
import CourseOverview from '../components/courses/overview'
import Lesson from '../components/courses/lesson'
import Interview from '../components/courses/interview'
import Submissions from '../components/submissions/index'
import CourseDescription from '../components/courses/description'
import CourseWeek from '../components/courses/week'

const AdminRoutes = lazy(() => import('../components/admin'))
const Dashboard = lazy(() => import('../components/dashboard'))

const AdminSuspense = () => {
  const user = useAppSelector((s) => s.authentication.user)
  const isInitialRequestDone = useAppSelector((s) => s.authentication.isInitialRequestDone)
  
  if (!isInitialRequestDone) {
    return <Loader />
  }

  if (!user?.role || user.role !== 'admin') {
    return null
  }
  
  return (
    <Suspense fallback={<Loader />}>
      <AdminRoutes />
    </Suspense>
  )
}

const Redirect = ({ to }: { to: string | { pathname: string, search?: string | false } }) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (typeof to === 'object' && to.pathname) {
      const path = to.search ? `${to.pathname}?${to.search}` : to.pathname
      navigate(path)
    }
    else if (typeof to === 'string') {
      navigate(to)
    }
  }, [navigate, to])
  return null
}

const DashboardSuspense = () => (
  <Suspense fallback={<Loader />}>
    <Dashboard />
  </Suspense>
)

const { getQueryStringParam } = queryString

const AnonymousRoute = ({ children }: { children?: ReactNode }) => {
  const user = useAppSelector((state) => state.authentication.user)
  const token = useAppSelector((state) => state.authentication.token)
  const isInitialRequestDone = useAppSelector((s) => s.authentication.isInitialRequestDone)
  const navigate = useNavigate()

  useEffect(() => {
    if (!isInitialRequestDone) {
      return
    }

    if (!!user && !!user.name && !!token) {
      navigate(`/${i18n.language}/dashboard`)
    }
  }, [user, token, isInitialRequestDone, navigate])


  return <>{children}</>
}

const PrivateRoute = ({ children }: { children?: ReactNode }) => {
  const location = useLocation()
  const user = useAppSelector((state) => state.authentication.user)
  const token = useAppSelector((state) => state.authentication.token)
  const isInitialRequestDone = useAppSelector((s) => s.authentication.isInitialRequestDone)
  const navigate = useNavigate()

  useEffect(() => { 
    if (!isInitialRequestDone) {
      return
    }
    if (!!user && !!user.name && !!token) {
      return
    }
    if (location.pathname !== '/') {
      navigate(`/${i18n.language}/login?redirect_from=${encodeURIComponent(location.pathname)}`)
    }
  }, [user, token, isInitialRequestDone, navigate, location.pathname])

  if (!isInitialRequestDone) {
    return <Loader />
  }

  return <>{children}</>
}

const PrivateRoleRoute = ({ children, userRole }: { children: ReactNode, userRole: string }) => {
  const location = useLocation()
  const user = useAppSelector((state) => state.authentication.user)
  const token = useAppSelector((state) => state.authentication.token)
  const isInitialRequestDone = useAppSelector((s) => s.authentication.isInitialRequestDone)
  const navigate = useNavigate()

  useEffect(() => {
    if (!isInitialRequestDone) {
      return
    }

    // First check if user is authenticated
    if (!user?.name || !token) {
      navigate(`/${i18n.language}/login?redirect_from=${encodeURIComponent(location.pathname)}`)
      return
    }

    // Then safely check the role
    if (user?.role !== userRole) {
      navigate(`/${i18n.language}/login?redirect_from=${encodeURIComponent(location.pathname)}`)
    }
  }, [user, token, isInitialRequestDone, userRole, navigate, location.pathname])

  if (!isInitialRequestDone) {
    return <Loader />
  }

  return <>{children}</>
}

Sentry.init({
  dsn: process.env.SENTRY_CLIENT_URL
})

export const LanguageRoute = ({ ...rest }) => {
  const { lng } = useParams<{ lng: string }>()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (['en', 'ru', 'uk'].indexOf(lng || '') === -1) {
      navigate(`/en${location.pathname}`)
    } else {
      i18n.changeLanguage(lng)
    }
  }, [lng, location.pathname, navigate])

  return <Outlet />
}

export const changeLanguage = (locale: string, navigate: ((str: string) => void)) => {
  if (typeof window !== 'undefined') {
    if (typeof window !== 'undefined' && window.location.pathname === '/') {
      navigate(`/${locale}`)
    }
    navigate(window.location.pathname.replace(/\/(en|ru|uk)\//, `/${locale}/`))
  }
  i18n.changeLanguage(locale)
}

const Root = () => (
  <I18nextProvider i18n={i18n}>
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <Provider store={store}>
        <DndProvider backend={HTML5Backend}>
          <Startup>
            <BrowserRouter>
              <CookieConsent />
              <Routes>
                <Route path="/" element={<Redirect to={`/${i18n.language}`} />} />
                <Route path="/:lng" element={<LanguageRoute />} >
                  <Route index element={<LandingDefault />} />
                  <Route path="login" element={<AnonymousRoute><Login /></AnonymousRoute>} />
                  <Route path="forgot-password" element={<AnonymousRoute><ForgotPassword /></AnonymousRoute>} />
                  <Route path="login/verified/:email" element={<AnonymousRoute><Login /></AnonymousRoute>} />
                  <Route path="profile" element={<PrivateRoute><DashboardSuspense /></PrivateRoute>} >
                    <Route path="" element={<Profile />} />
                    <Route path="security" element={<Security />} />
                    <Route path="billing" element={<Billing />} />
                    <Route path="job-search" element={<ProfileJobSearch />} /> 
                    <Route path="social" element={<ProfileSocial />} /> 
                    <Route path="api-keys" element={<ApiKeys />} />
                    <Route path="submissions" element={<Submissions />} />
                  </Route>
                  <Route path="submissions" element={<PrivateRoute><DashboardSuspense /></PrivateRoute>} >
                    <Route path="" element={<Submissions />} />
                  </Route>
                  <Route path="profile/*" element={<PrivateRoute><DashboardSuspense /></PrivateRoute>} />
                  <Route path="dashboard" element={<PrivateRoute><DashboardSuspense /></PrivateRoute>} >
                    <Route path="" element={<CourseList />} />
                  </Route>
                  <Route path="course-list" element={<PrivateRoute><DashboardSuspense /></PrivateRoute>} />
                  <Route path="course/*" element={<PrivateRoute><DashboardSuspense /></PrivateRoute>} >
                    <Route path="list" element={<CourseList />} />
                    <Route path=":id" element={<CourseOverview />} >
                      <Route path="" element={<CourseDescription />} />
                      <Route path=":weekId" element={<CourseWeek />} />
                    </Route>
                    <Route path=":id/:weekId/interview" element={<Interview />} />
                    <Route path=":id/:weekId/:lessonId" element={<Lesson />} />
                  </Route>
                  <Route path="admin/*" element={<PrivateRoleRoute userRole="admin"><DashboardSuspense /></PrivateRoleRoute>}>
                    <Route path="*" element={<AdminSuspense />} />
                  </Route>
                  <Route path="faq" element={<Redirect to="/courses/fullstack" />} />
                  <Route path="courses" element={<LandingDefaultCourses />} />
                  <Route path="playground" element={<Playground />} />
                  <Route path="courses/:key" element={<LandingDefaultCourseDetails />} />
                  <Route path="enroll/:key" element={<LandingDefaultCourseEnroll />} />
                  <Route path="enroll/:key/:email" element={<LandingDefaultCourseEnroll />} />
                  <Route path="faq" element={<LandingDefaultFaq />} />
                  <Route path="terms-and-conditions" element={<LandingDefaultTermsAndConditions />} />
                  <Route path="privacy-policy" element={<LandingDefaultPrivacyPolicy />} />
                  <Route path="disclaimers" element={<LandingDefaultDisclaimers />} />
                  <Route path="*" element={<NotFound />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </Startup>
        </DndProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  </I18nextProvider>
)

export default Root
