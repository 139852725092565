import {
	createSlice,
	type Dispatch,
	type PayloadAction,
	createAsyncThunk,
} from "@reduxjs/toolkit";
import type {
	Course,
	CourseWeek,
	Lesson,
	LessonFeedback,
	Week,
} from "../../interfaces/mongoose.gen";

const LOAD_HOMEWORK_REQUESTED = "skills.crucial\\LOAD_HOMEWORK_REQUESTED";
const LOAD_HOMEWORK = "skills.crucial\\LOAD_HOMEWORK";
const LOAD_HOMEWORK_ERROR = "skills.crucial\\LOAD_HOMEWORK_ERROR";
const SEND_HOMEWORK_ERROR = "skills.crucial\\SEND_HOMEWORK_ERROR";
const SEND_HOMEWORK_OK = "skills.crucial\\SEND_HOMEWORK_OK";

const CLEAR_MESSAGES = "skills.crucial\\CLEAR_MESSAGES";
const WEB_TASK_IS_DONE = "WEB_TASK_IS_DONE";
const UPDATE_WEEK_LESSONS = "UPDATE_WEEK_LESSONS";

const JS_TASK_DONE = "JS_TASK_IS_DONE";
const RESET_CODE = "RESET_CODE";
const CODE_UPDATE = "CODE_UPDATE";
const CREATE_LESSON = "skillcrucial\\CREATE_LESSON";
const UPDATE_WEEK_LESSONS_FETCHING = "UPDATE_WEEK_LESSONS_FETCHING";
const UPDATE_WEEK_LESSONS_FETCHING_FINISHED =
	"UPDATE_WEEK_LESSONS_FETCHING_FINISHED";

interface HomeworkTaskRecord {
	taskId: string;
	code?: string;
	url?: string;
	timestamp?: number;
	result?: {
		status: string;
		messages: Array<{ message: string }>;
		error?: string;
		details?: string[];
	};
}

interface HomeworkStatus {
	[taskId: string]: HomeworkTaskRecord;
}

interface CoursesState {
	isRequesting: boolean;
	list: Partial<Course>[];
	questions: {
		[lessonId: string]: LessonFeedback[];
	};
	homeworks: Record<string, Course>;
	homeworkStatuses: Record<string, HomeworkStatus>;
	isInitialRequestDone: boolean;
	isFetching: boolean;
}

const initialState: CoursesState = {
	isRequesting: false,
	list: [],
	questions: {},
	homeworks: {},
	homeworkStatuses: {},
	isInitialRequestDone: false,
	isFetching: false,
};

const coursesSlice = createSlice({
	name: "courses",
	initialState,
	reducers: {
		updateWeekLessonsFetching(state) {
			state.isFetching = true;
		},
		updateWeekLessonsFetchingFinished(state) {
			state.isFetching = false;
		},
		setListOfCourses(state, action: PayloadAction<Course[]>) {
			// remove duplicates by {id}
			const unique = action.payload.filter(
				(item, index, self) =>
					index === self.findIndex((t) => t.courseId === item.courseId),
			);
			state.list = unique;
			state.isInitialRequestDone = true;
		},
		updateWeekLessons(
			state,
			action: PayloadAction<{ week: CourseWeek; lessons: Lesson[] }>,
		) {
			state.list = state.list.map((course) => ({
				...course,
				weeks: (course.weeks ?? []).map((week) => ({
					...week,
					lessons: (action.payload.week._id === week._id
						? week.lessonIds.map((it2) =>
								week.lessons?.find((lesson) => lesson._id === it2),
							)
						: week.lessons ?? []
					).filter(Boolean) as Lesson[],
					isLoaded: action.payload.week._id === week._id || week.isLoaded,
				})),
			}));
		},
		setListOfQuestions(
			state,
			action: PayloadAction<{ lessonId: string; list: LessonFeedback[] }>,
		) {
			state.questions[action.payload.lessonId] = action.payload.list;
		},
		createLesson(
			state,
			action: PayloadAction<{ courseId: string; week: Week; data: Lesson }>,
		) {
			state.list = state.list.map((it) => {
				return it.courseId !== action.payload.courseId
					? it
					: {
							...it,
							weeks: (it.weeks ?? []).map((w) => {
								return w._id !== action.payload.week._id
									? w
									: {
											...w,
											lessonIds: [...w.lessonIds, action.payload.data._id || ''],
											lessons: [...(w.lessons || []), action.payload.data],
											isLoaded: true, // Mark as loaded to ensure the new lesson is visible
										};
							}),
						};
			});
		},
		loadHomework(
			state,
			action: PayloadAction<{
				homeworkId: string;
				homework: Course;
				homeworkStatuses: {
					jsTasks: HomeworkTaskRecord[];
					webTasks: HomeworkTaskRecord[];
				};
			}>,
		) {
			state.homeworks[action.payload.homeworkId] = action.payload.homework;
			const newStatuses = [
				...action.payload.homeworkStatuses.jsTasks,
				...action.payload.homeworkStatuses.webTasks,
			].reduce<Record<string, HomeworkTaskRecord>>((acc, rec) => {
				acc[rec.taskId] = rec;
				return acc;
			}, {});

			state.homeworkStatuses[action.payload.homeworkId] = {
				...state.homeworkStatuses[action.payload.homeworkId],
				...newStatuses,
			};
		},
		webTaskIsDone(
			state,
			action: PayloadAction<{
				homeworkId: string;
				taskId: string;
				url: string;
				timestamp: number;
				result: HomeworkTaskRecord["result"];
			}>,
		) {
			state.homeworkStatuses[action.payload.homeworkId][action.payload.taskId] =
				{
					taskId: action.payload.taskId,
					url: action.payload.url,
					timestamp: action.payload.timestamp,
					result: {
						status: "ok",
						...action.payload.result,
						messages: action.payload.result?.messages || [],
					},
				};
		},
		jsTaskDone(
			state,
			action: PayloadAction<{
				homeworkId: string;
				taskId: string;
				code: string;
				timestamp: number;
				result: HomeworkTaskRecord["result"];
			}>,
		) {
			state.homeworkStatuses[action.payload.homeworkId][action.payload.taskId] =
				{
					taskId: action.payload.taskId,
					code: action.payload.code,
					timestamp: action.payload.timestamp,
					result: {
						status: "ok",
						...action.payload.result,
						messages: action.payload.result?.messages || [],
					},
				};
		},
		codeUpdate(
			state,
			action: PayloadAction<{
				homeworkId: string;
				taskId: string;
				code: string;
			}>,
		) {
			state.homeworkStatuses[action.payload.homeworkId][action.payload.taskId] =
				{
					taskId: action.payload.taskId,
					code: action.payload.code,
					result: undefined,
				};
		},
		resetCode(
			state,
			action: PayloadAction<{
				homeworkId: string;
				taskId: string;
				code: string;
			}>,
		) {
			state.homeworkStatuses[action.payload.homeworkId][action.payload.taskId] =
				{
					taskId: action.payload.taskId,
					code: action.payload.code,
					result: undefined,
				};
		},
		clearMessages(
			state,
			action: PayloadAction<{
				homeworkId: string;
				taskId: string;
				code: string;
				timestamp: number;
			}>,
		) {
			state.homeworkStatuses[action.payload.homeworkId][action.payload.taskId] =
				{
					taskId: action.payload.taskId,
					code: action.payload.code,
					timestamp: action.payload.timestamp,
					result:
						state.homeworkStatuses[action.payload.homeworkId][
							action.payload.taskId
						].result,
				};
		},
		sendHomeworkError(
			state,
			action: PayloadAction<{
				homeworkId: string;
				taskId: string;
				code: string;
				timestamp: number;
				messages?: Array<{ message: string }>;
				error?: string;
				details?: string[];
			}>,
		) {
			if (!state.homeworkStatuses[action.payload.homeworkId]) {
				state.homeworkStatuses[action.payload.homeworkId] = {};
			}

			state.homeworkStatuses[action.payload.homeworkId][action.payload.taskId] =
				{
					taskId: action.payload.taskId,
					code: action.payload.code,
					timestamp: action.payload.timestamp,
					result: {
						status: "error",
						messages: action.payload.messages || [],
						error: action.payload.error,
						details: action.payload.details,
					},
				};
		},
		sendHomeworkOk(
			state,
			action: PayloadAction<{
				homeworkId: string;
				taskId: string;
				code: string;
				timestamp: number;
			}>,
		) {
			state.homeworkStatuses[action.payload.homeworkId][action.payload.taskId] =
				{
					taskId: action.payload.taskId,
					code: action.payload.code,
					timestamp: action.payload.timestamp,
					result: {
						status: "ok",
						messages: [],
					},
				};
		},
		updateLesson(
			state,
			action: PayloadAction<{
				courseId: string;
				weekId: string;
				lesson: Lesson;
			}>,
		) {
			state.list = state.list.map((course) => {
				if (course.courseId !== action.payload.courseId) return course;
				
				return {
					...course,
					weeks: (course.weeks ?? []).map((week) => {
						if (week._id !== action.payload.weekId) return week;
						
						return {
							...week,
							lessons: (week.lessons ?? []).map((lesson) => {
								if (lesson._id !== action.payload.lesson._id) return lesson;
								return action.payload.lesson;
							}),
						};
					}),
				};
			});
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getListOfCourses.pending, (state) => {
			state.isRequesting = true;
		});
		builder.addCase(getListOfCourses.fulfilled, (state, action) => {
			state.isRequesting = false;
			const unique = (action.payload as Course[]).filter(
				(item, index, self) =>
					index === self.findIndex((t) => t.courseId === item.courseId),
			);
			state.list = unique;
			state.isInitialRequestDone = true;
		});
		builder.addCase(getListOfCourses.rejected, (state) => {
			state.isRequesting = false;
		});
		builder.addCase(loadLessons.fulfilled, (state, action) => {
			state.list = state.list.map((list) => {
				return {
					...list,
					weeks: (list.weeks ?? []).map((week) => {
						if (action.payload.week._id !== week._id) return week;
						return { ...week, lessons: action.payload.lessons, isLoaded: true };
					}),
				};
			});
		});
		builder.addCase(getQuestionsListForLesson.fulfilled, (state, action) => {
			state.questions[action.payload.lessonId] = action.payload.list;
		});
		builder.addCase(submitQuestion.fulfilled, (state, action) => {
			state.questions[action.payload.lessonId] = action.payload.list;
		});
		builder.addCase(getHomeworkForLesson.fulfilled, (state, action) => {
			state.homeworks[action.payload.homeworkId] = action.payload.homework;
		});
	},
});

export const {
	updateWeekLessonsFetching,
	updateWeekLessonsFetchingFinished,
	setListOfCourses,
	updateWeekLessons,
	setListOfQuestions,
	createLesson,
	loadHomework,
	webTaskIsDone,
	jsTaskDone,
	codeUpdate,
	resetCode,
	clearMessages,
	sendHomeworkError,
	sendHomeworkOk,
	updateLesson,
} = coursesSlice.actions;

export const getListOfCourses = createAsyncThunk(
	"courses/getListOfCourses",
	async () => {
		const response = await fetch("/api/v1/courses/list", {
			method: "GET",
			mode: "cors",
			cache: "no-cache",
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
			},
			redirect: "follow", // manual, *follow, error
			referrer: "no-referrer",
		});
		const json = await response.json();

		if (json.data.length > 0) {
			return json.data;
		}
		throw new Error("Failed to fetch list of courses");
	},
);

export const getQuestionsListForLesson = createAsyncThunk(
	"courses/getQuestionsListForLesson",
	async (lessonId: string) => {
		const response = await fetch(
			`/api/v1/courses/lesson/${lessonId}/user-questions`,
			{
				method: "GET",
				mode: "cors",
				cache: "no-cache",
				credentials: "same-origin",
				headers: {
					"Content-Type": "application/json",
				},
				redirect: "follow", // manual, *follow, error
				referrer: "no-referrer",
			},
		);
		const json = await response.json();
		return { list: json.data, lessonId };
	},
);

export const submitQuestion = createAsyncThunk(
	"courses/submitQuestion",
	async (payload: { lessonId: string; question: string }, { dispatch }) => {
		const response = await fetch("/api/v1/courses/question/submit", {
			method: "POST",
			mode: "cors",
			cache: "no-cache",
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
			},
			redirect: "follow", // manual, *follow, error
			referrer: "no-referrer",
			body: JSON.stringify(payload),
		});
		const json = await response.json();

		dispatch(getQuestionsListForLesson(payload.lessonId));
		return json.list;
	},
);

export const sendAnswer = createAsyncThunk(
	"courses/sendAnswer",
	async (
		{
			lessonId,
			qId,
			answer,
		}: { lessonId: string; qId: string; answer: string },
		{ dispatch },
	) => {
		const response = await fetch(
			`/api/v1/courses/lesson/${lessonId}/user-questions/${qId}`,
			{
				method: "POST",
				mode: "cors",
				cache: "no-cache",
				credentials: "same-origin",
				headers: {
					"Content-Type": "application/json",
				},
				redirect: "follow", // manual, *follow, error
				referrer: "no-referrer",
				body: JSON.stringify({ answer }),
			},
		);
		const json = await response.json();

		if (json.status === "ok") {
			dispatch(getQuestionsListForLesson(lessonId));
			return { list: json.list, lessonId };
		}
		throw new Error("Failed to send answer");
	},
);

export function changeOption() {}

export const skipAnswer = createAsyncThunk(
	"courses/skipAnswer",
	async (
		{ lessonId, qId }: { lessonId: string; qId: string },
		{ dispatch },
	) => {
		const response = await fetch(
			`/api/v1/courses/lesson/${lessonId}/user-questions/${qId}/skip`,
			{
				method: "POST",
				mode: "cors",
				cache: "no-cache",
				credentials: "same-origin",
				headers: {
					"Content-Type": "application/json",
				},
				redirect: "follow", // manual, *follow, error
				referrer: "no-referrer",
			},
		);
		const json = await response.json();

		if (json.status === "ok") {
			dispatch(getQuestionsListForLesson(lessonId));
			return { list: json.list, lessonId };
		}
		throw new Error("Failed to skip question");
	},
);

export const getHomeworkForLesson = createAsyncThunk(
	"courses/getHomeworkForLesson",
	async (homeworkId: string, { dispatch }) => {
		dispatch({ type: LOAD_HOMEWORK_REQUESTED });
		try {
			const response = await fetch(`/api/v1/courses/homework/${homeworkId}`, {
				method: "GET",
				mode: "cors",
				cache: "no-cache",
				credentials: "same-origin",
				headers: {
					"Content-Type": "application/json",
				},
				redirect: "follow", // manual, *follow, error
				referrer: "no-referrer",
			});
			const json = await response.json();
			if (json.status === "ok") {
				return {
					homework: json.data.homework,
					homeworkStatuses: json.data.homeworkStatuses,
					homeworkId,
				};
			}
			throw new Error("Failed to load homework");
		} catch (error) {
			throw new Error("Failed to load homework");
		}
	},
);

export function sendJsTask(homeworkId: string, taskId: string, code: string) {
	return (dispatch: Dispatch) => {
		dispatch({ type: LOAD_HOMEWORK_REQUESTED });
		return fetch(`/api/v1/courses/homework/${homeworkId}/submit`, {
			method: "POST",
			mode: "cors",
			cache: "no-cache",
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
			},
			redirect: "follow",
			referrer: "no-referrer",
			body: JSON.stringify({ homeworkId, taskId, code, type: "javascript" }),
		})
			.then((res) => res.json())
			.then((json) => {
				const timestamp = Date.now();
				if (json.status === "ok") {
					dispatch(
						sendHomeworkOk({
							homeworkId,
							taskId,
							code,
							timestamp,
						}),
					);
				}
				if (json.status === "error") {
					dispatch(
						sendHomeworkError({
							homeworkId,
							taskId,
							code,
							timestamp,
							error: json.error,
							details: json.details,
							messages: json.messages || [],
						}),
					);
				}
			})
			.catch((error) => dispatch({ type: LOAD_HOMEWORK_ERROR, error }));
	};
}

export function sendJestTask(homeworkId: string, taskId: string, code: string) {
	return (dispatch: Dispatch) => {
		dispatch({ type: LOAD_HOMEWORK_REQUESTED });
		return fetch(`/api/v1/courses/homework/${homeworkId}/submit`, {
			method: "POST",
			mode: "cors",
			cache: "no-cache",
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
			},
			redirect: "follow",
			referrer: "no-referrer",
			body: JSON.stringify({ homeworkId, taskId, code, type: "jest" }),
		})
			.then((res) => res.json())
			.then((json) => {
				const timestamp = Date.now();
				if (json.status === "ok") {
					dispatch(
						sendHomeworkOk({
							homeworkId,
							taskId,
							code,
							timestamp,
						}),
					);
				}
				if (json.status === "error") {
					dispatch(
						sendHomeworkError({
							homeworkId,
							taskId,
							code,
							timestamp,
							error: json.error,
							details: json.details,
							messages: json.messages || [],
						}),
					);
				}
			})
			.catch((error) => dispatch({ type: LOAD_HOMEWORK_ERROR, error }));
	};
}

export const loadLessons = createAsyncThunk(
	"courses/loadLessons",
	async (week: CourseWeek, { dispatch }) => {
		dispatch({ type: UPDATE_WEEK_LESSONS_FETCHING });
		if (week.lessonIds.length === 0) {
			return { week, lessons: [] };
		}
		const response = await fetch("/api/v1/courses/lessons", {
			method: "POST",
			mode: "cors",
			cache: "no-cache",
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Bearer ${localStorage.getItem('token')}`
			},
			redirect: "follow", // manual, *follow, error
			referrer: "no-referrer",
			body: JSON.stringify({ lessonIds: week.lessonIds }),
		});
		const lessons = await response.json();
		return { week, lessons: lessons.data };
	},
);

export function sendWebTask(homeworkId: string, taskId: string, url: string) {
	return (dispatch: Dispatch) => {
		dispatch({ type: LOAD_HOMEWORK_REQUESTED });
		return fetch(`/api/v1/courses/homework/${homeworkId}/submit`, {
			method: "POST",
			mode: "cors",
			cache: "no-cache",
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
			},
			redirect: "follow",
			referrer: "no-referrer",
			body: JSON.stringify({ homeworkId, taskId, url, type: "web" }),
		})
			.then((res) => res.json())
			.then((json) => {
				const timestamp = Date.now();
				if (json.status === "ok") {
					dispatch(
						sendHomeworkOk({
							homeworkId,
							taskId,
							code: url,
							timestamp,
						}),
					);
				}
				if (json.status === "error") {
					dispatch(
						sendHomeworkError({
							homeworkId,
							taskId,
							code: url,
							timestamp,
							error: json.error,
							details: json.details,
							messages: json.messages || [],
						}),
					);
				}
			})
			.catch((error) => dispatch({ type: LOAD_HOMEWORK_ERROR, error }));
	};
}

export default coursesSlice.reducer;
