import { useState, useEffect } from 'react'
import type { FC } from 'react'
import { KeyIcon } from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import { useAppSelector } from '../../data'

interface ApiKey {
  _id?: string
  service: string
  key: string
  description?: string
  createdAt?: Date
  updatedAt?: Date
}

const ApiKeys: FC = () => {
  const user = useAppSelector((state) => state.authentication.user)
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [isAdding, setIsAdding] = useState(false)
  const [service, setService] = useState('')
  const [key, setKey] = useState('')
  const [description, setDescription] = useState('')

  useEffect(() => {
    // Fetch the user's API keys
    const fetchApiKeys = async () => {
      try {
        setIsLoading(true)
        const response = await fetch(`/api/v1/admin/user/${user?.id}`)
        const data = await response.json()
        
        if (data.status === 'ok' && data.data) {
          setApiKeys(data.data.apiKeys || [])
        }
      } catch (error) {
        console.error('Failed to fetch API keys:', error)
      } finally {
        setIsLoading(false)
      }
    }

    if (user?.id) {
      fetchApiKeys()
    }
  }, [user?.id])

  const handleAddKey = async () => {
    if (!service || !key) return

    try {
      const response = await fetch(`/api/v1/admin/user/apikeys/${user?.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          service,
          key,
          description
        })
      })
      
      const data = await response.json()
      
      if (data.status === 'ok' && data.data) {
        setApiKeys(data.data.apiKeys || [])
        
        // Reset form
        setService('')
        setKey('')
        setDescription('')
        setIsAdding(false)
      }
    } catch (error) {
      console.error('Failed to add API key:', error)
    }
  }

  const handleRemoveKey = async (keyId: string) => {
    try {
      const response = await fetch(`/api/v1/admin/user/apikeys/${user?.id}/${keyId}`, {
        method: 'DELETE'
      })
      
      const data = await response.json()
      
      if (data.status === 'ok' && data.data) {
        setApiKeys(data.data.apiKeys || [])
      }
    } catch (error) {
      console.error('Failed to remove API key:', error)
    }
  }

  if (!user || user.role !== 'admin') {
    return (
      <div className="py-6 px-4 sm:p-6 lg:pb-8">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
          <h2 className="text-lg font-medium text-gray-900 dark:text-white">API Keys</h2>
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
            This feature is only available for admin users.
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className="py-6 px-4 sm:p-6 lg:pb-8">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow">
        <div className="px-6 py-5 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
          <div>
            <h2 className="text-lg font-medium text-gray-900 dark:text-white">API Keys</h2>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
              Manage your API keys for Claude, ChatGPT, and DeepSeek.
            </p>
          </div>
          <button
            type="button"
            onClick={() => setIsAdding(!isAdding)}
            className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            {isAdding ? 'Cancel' : 'Add Key'}
          </button>
        </div>
        
        {isAdding && (
          <div className="p-6 border-b border-gray-200 dark:border-gray-700">
            <div className="space-y-4">
              <div>
                <label htmlFor="service" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Service
                </label>
                <select
                  id="service"
                  value={service}
                  onChange={(e) => setService(e.target.value)}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                >
                  <option value="">Select a service</option>
                  <option value="claude">Claude</option>
                  <option value="chatgpt">ChatGPT</option>
                  <option value="deepseek">DeepSeek</option>
                  <option value="other">Other</option>
                </select>
              </div>
              
              <div>
                <label htmlFor="key" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  API Key
                </label>
                <input
                  type="text"
                  id="key"
                  value={key}
                  onChange={(e) => setKey(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  placeholder="Enter API key"
                />
              </div>
              
              <div>
                <label htmlFor="description" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Description (optional)
                </label>
                <input
                  type="text"
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  placeholder="Enter description"
                />
              </div>
              
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={handleAddKey}
                  disabled={!service || !key}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-gray-400 disabled:cursor-not-allowed"
                >
                  Add API Key
                </button>
              </div>
            </div>
          </div>
        )}
        
        <div className="p-6">
          {isLoading ? (
            <div className="flex justify-center">
              <div className="animate-spin rounded-full h-8 w-8 border-2 border-blue-500 border-t-transparent"></div>
            </div>
          ) : apiKeys.length > 0 ? (
            <ul className="divide-y divide-gray-200 dark:divide-gray-700">
              {apiKeys.map((apiKey) => (
                <li
                  key={apiKey._id}
                  className="flex items-center justify-between py-4 first:pt-0 last:pb-0"
                >
                  <div className="flex items-center min-w-0">
                    <KeyIcon 
                      className="h-5 w-5 text-gray-400 flex-shrink-0" 
                      aria-hidden="true" 
                    />
                    <div className="ml-3 flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 dark:text-white truncate">
                        {apiKey.service.charAt(0).toUpperCase() + apiKey.service.slice(1)}
                      </p>
                      <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
                        {apiKey.key}
                      </p>
                      {apiKey.description && (
                        <p className="text-xs text-gray-500 dark:text-gray-400 truncate">
                          {apiKey.description}
                        </p>
                      )}
                      {apiKey.createdAt && (
                        <p className="text-xs text-gray-500 dark:text-gray-400">
                          Added {dayjs(new Date(apiKey.createdAt)).format('MMM D, YYYY')}
                        </p>
                      )}
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => handleRemoveKey(apiKey._id as string)}
                    className="ml-4 flex-shrink-0 text-sm font-medium text-red-600 hover:text-red-500 dark:text-red-400 dark:hover:text-red-300"
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-sm text-gray-500 dark:text-gray-400">
              No API keys available. Add your first API key to get started.
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default ApiKeys
