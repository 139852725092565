import { createSlice, type Dispatch } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import Cookies from 'universal-cookie'
import * as Sentry from '@sentry/react'
import { loadCodewarsLeaderboard, loadStackoverflowLeaderboard } from './leaderboard'
import type { User, UserAttachment, UserChargePlan, WorkProfile } from '../../interfaces/mongoose.gen'
import { initSocket } from '../sockets'
import type { RootState } from ".."
import api from '../../services/api'

const cookies = new Cookies()

interface UserInfo {
  state: {
    isPasswordSet: boolean
  }
}

interface AuthenticationState {
  token: string
  isInitialRequestDone: boolean
  isRequesting: boolean
  user: Partial<User> & { sid?: string }
  userInfo?: UserInfo
  error?: string
  isImpersonating: boolean
}

const initialState: AuthenticationState = {
  token: cookies.get('Access'),
  isInitialRequestDone: false,
  isRequesting: false,
  user: {
    name: '',
    id: '',
    role: undefined,
    sid: encodeURIComponent(cookies.get('sid'))
  },
  isImpersonating: false
}

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setSid: (state, action: PayloadAction<string>) => {
      state.user.sid = encodeURIComponent(action.payload)
    },
    subscribeToTheCourse: (state, action: PayloadAction<string>) => {
      state.user.chargePlans = (state.user.chargePlans ?? []).map((it) => {
        return it.courseId?.toString() !== action.payload
          ? it
          : {
              ...it,
              isActive: true,
              justPayed: true
            }
      })
    },
    passwordChange: (state) => {
      if (state.userInfo?.state) {
        state.userInfo.state.isPasswordSet = true
      }
    },
    passwordNotSet: (state) => {
      if (state.userInfo?.state) {
        state.userInfo.state.isPasswordSet = false
      }
    },
    userLogout: (state) => {
      state.user = initialState.user
      state.token = ''
      state.isInitialRequestDone = false
      state.isImpersonating = false
    },
    initialRequestDone: (state) => {
      state.isInitialRequestDone = true
    },
    loginUser: (state, action: PayloadAction<{ user: User; token: string }>) => {
      state.user = action.payload.user
      state.token = action.payload.token
      state.isInitialRequestDone = true
    },
    loginUserError: (state, action: PayloadAction<string>) => {
      state.error = action.payload
      state.isInitialRequestDone = true
    },
    loginUserFailed: (state) => {
      state.token = ''
      state.isInitialRequestDone = true
    },
    tryGetUserInfo: (state, action: PayloadAction<UserInfo>) => {
      state.userInfo = action.payload
    },
    startImpersonating: (state, action: PayloadAction<{ user: User; token: string }>) => {
      state.user = action.payload.user
      state.token = action.payload.token
      state.isImpersonating = true
    },
    stopImpersonating: (state) => {
      state.isImpersonating = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(tryLogin.fulfilled, (state, action) => {
      state.isInitialRequestDone = true

      if (action.payload !== null) {
        const {user, token, isImpersonating = false} = action.payload
        Sentry.setUser({ email: user.email })

        state.user = action.payload.user
        state.token = action.payload.token
        state.isImpersonating = isImpersonating
        state.error = undefined;
        try {
          initSocket()
        } catch (err) {
          console.error('Socket initialization failed:', err)
        }
      }
    })
    builder.addCase(tryLogin.rejected, (state, action) => {
      state.isInitialRequestDone = true
      if (!action.error.message?.includes('401')) {
        state.error = action.error.message
      }
      state.token = ''
    })

    builder.addCase(login.rejected, (state, action) => {
      state.isInitialRequestDone = false
      // Provide a user-friendly error message for 401 errors (invalid credentials)
      if (action.error.message?.includes('401')) {
        state.error = 'Invalid email or password. Please try again.'
      } else {
        state.error = action.error.message
      }
      state.token = ''
      state.isImpersonating = false
      cookies.remove('Access', { path: '/' })
      cookies.remove('IACCESS', { path: '/' })
    })

    builder.addCase(login.fulfilled, (state, action) => {
      state.isInitialRequestDone = true

      if (action.payload !== null) {
        const {user, token, isImpersonating = false} = action.payload
        Sentry.setUser({ email: user.email })

        state.user = action.payload.user
        state.token = action.payload.token
        state.isImpersonating = isImpersonating
        state.error = undefined;
        try {
          initSocket()
        } catch (err) {
          console.error('Socket initialization failed:', err)
        }
      }
    })

    builder.addCase(registration.fulfilled, (state, action) => {
      if (action.payload !== null) {
        const {user, token} = action.payload
        Sentry.setUser({ email: user.email })

        state.user = action.payload.user
        state.token = action.payload.token
        state.error = undefined;
        try {
          initSocket()
        } catch (err) {
          console.error('Socket initialization failed:', err)
        }
      }
    })

    builder.addCase(impersonate.fulfilled, (state, action) => {
      if (action.payload !== null) {
        const { user, token } = action.payload
        state.user = user
        state.token = token
        state.isImpersonating = true
        state.error = undefined
      }
    })
  }
})

export const {
  setSid,
  subscribeToTheCourse,
  passwordChange,
  passwordNotSet,
  userLogout,
  initialRequestDone,
  loginUser,
  loginUserError,
  loginUserFailed,
  tryGetUserInfo,
  startImpersonating,
  stopImpersonating
} = authenticationSlice.actions

export default authenticationSlice.reducer

export const logout = (navigate: (str: string) => void, redirect = 'normal') => {
  return async (dispatch:Dispatch) => {
    try {
      // Call backend signout endpoint first
      await api.post('/authentication/signout')
    } catch (error) {
      console.error('Error during signout:', error)
    } finally {
      // Remove cookies locally regardless of backend success
      cookies.remove('Access', { path: '/' })
      cookies.remove('IACCESS', { path: '/' })
      cookies.remove('UACCESS', { path: '/' })

      dispatch(userLogout())
      if (redirect === 'normal') {
        navigate('/')
      }
    }
  }
}

interface UserResponse {
  token: string
  isImpersonating?: boolean
  user: {
    id: string
    name: string
    email: string
    workProfile: WorkProfile
    homeworkId: string
    sex: string
    picture: string
    github: Record<string, unknown>
    attachments: UserAttachment[]
    linkedIn: Record<string, unknown>
    twitter: Record<string, unknown>
    registrationDate: Date
    codewarsApiKey: string
    codewarsUserName: string
    codewarsProfile: Record<string, unknown>
    stackOverflow: Record<string, unknown>
    chargePlans: (UserChargePlan & {
      isFree: boolean
      isRevision: boolean
    })[]
    achievements: string[]
    role?: "user" | "admin" | "moderator" | "recruiter"
  }
}

export const tryLogin = createAsyncThunk<UserResponse | null, void, {state: RootState }>(
  'auth/tryLogin',
  async (_, { dispatch }) => { 
      const response = await api.post('/authentication/verify', {})
      if (response.data) {
        dispatch(loadCodewarsLeaderboard())
        dispatch(loadStackoverflowLeaderboard())
        return { ...response.data}
      }
  
      return null

  }
)

export const login = createAsyncThunk<UserResponse | null, { email: string, password: string }, {state: RootState }>(
  'auth/login',
  async ({ email, password }, { dispatch }) => {
    try {
      const response = await api.post('/authentication/login', { email, password })
      dispatch(loadCodewarsLeaderboard())
      dispatch(loadStackoverflowLeaderboard())
      return { ...response.data, isImpersonating: false }
    } catch (error) {
      cookies.remove('Access', { path: '/' })
      cookies.remove('IACCESS', { path: '/' })
      throw error
    }
  }
)

export const registration = createAsyncThunk<UserResponse | null, { email: string, password: string, name: string }, {state: RootState }>(
  'auth/registration',
  async ({ email, password, name }, { dispatch }) => {
    try {
      const response = await api.post('/authentication/registration', { email, password, name })
      dispatch(loadCodewarsLeaderboard())
      dispatch(loadStackoverflowLeaderboard())
      return response.data
    } catch (error) {
      cookies.remove('Access', { path: '/' })
      cookies.remove('IACCESS', { path: '/' })
      throw error
    }
  }
)

export const impersonate = createAsyncThunk<UserResponse | null, { userId: string }, { state: RootState }>(
  'auth/impersonate',
  async ({ userId }, { dispatch }) => {
    try {
      const response = await api.post('/authentication/impersonate', { userId })
      const { token } = response.data

      // Verify the impersonation token to get user data
      const verifyResponse = await api.post('/authentication/verify', { token })
      return { ...verifyResponse.data }
    } catch (error) {
      cookies.remove('IACCESS', { path: '/' })
      throw error
    }
  }
)
